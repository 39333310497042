import Rest from '@/services/Rest';

/**
 * @typedef {LoginGoogleService}
 */
export default class LoginGoogleService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/social/google'
}
