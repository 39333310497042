<template>
<div>
    <section class="container-fluid p-0" :style="{'background-color': background_color}">
    <div class="col-12">
        <div class="row">
            <div class="col-12 col-md-12 col-lg-6 d-flex bg-login"  :style="{'background-image': `url(${required(desktop_background)})`}">
                <div class="align-self-end p-bg-login">
                    <h1 class="text-white">{{ sentence }}
                    </h1>
                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-6 my-auto">
                <div class="mb-4 col-12 d-flex justify-content-center p-logo-login">
                    <img :src="logo" class="logo-login">
                </div>
                <div v-if="active_register == 1 || !active_register" class="col-12 text-center">
                    <p>Para começar, faça o login na sua conta:</p>
                </div>
                <div v-if="active_register == 1 || !active_register">
                    <div class="col-12 text-center">
                        <div class="d-flex mx-auto" style="width: 125px;">
                            <div class="mx-auto">
                                <a href="javascript:void(0);" title="Google" @click="loginGoogle">
                                    <img src="~@/assets/img/ico-login-google.png" alt="Google" height="34">
                                </a>
                            </div>
                            <div class="mx-auto">
                                <a href="javascript:void(0);" title="Facebook" @click="loginFacebook">
                                    <img src="~@/assets/img/ico-login-facebook.png" alt="Facebook" height="34">
                                </a>
                            </div>
                            <div class="mx-auto">
                                <a href="javascript:void(0);" title="Twiter" @click="loginTwitter">
                                    <img src="~@/assets/img/ico-login-twitter.png" alt="Twiter" height="34">
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 text-center" style="padding-top:10px;">
                        <p>ou</p>
                    </div>
                </div>
                <div class="col-12 col-md-10 col-lg-8 mx-auto pb-4">
                    <form class="needs-validation" novalidate @submit.prevent="login">
                        <div class="col-12">
                            <label class="d-none">E-mail </label>
                            <input type="text" class="form-control inputs-login" placeholder="exemplo@exemplo.com" v-validate="'required|email'" name="email" v-model="email">
                            <div class="invalid-feedback pl-4 pb-3">Por favor! Informe o seu e-mail.</div>
                        </div>
                        <div class="col-12 pt-2">
                            <label class="d-none">Senha </label>
                            <input type="password" class="form-control inputs-login" id="passtext" placeholder="********" v-model="password">
                        </div>
                        <b-form-invalid-feedback :state="!error">
                           Email ou senha incorretos.
                        </b-form-invalid-feedback>
                        <div class="col-12 text-right pt-2">
                            <b-link :to="{name: forget_password }" title="Senha" class="font-12 a-link-1">Esqueci minha senha?</b-link>
                        </div>
                        <div class="col-12 text-center pt-3">
                            <button type="submit" class="btn btn-info button-login" style="background-color: var(--color)">Entrar</button>
                        </div>
                        <div class="col-12 text-center pt-2" v-if="active_register == 1 || !active_register">
                            Ainda não é cadastrado? <b-link :to="{name: 'ClientRegister'}" class="font-13 a-link-1">Clique aqui</b-link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<BaseLoginFooter :logo="logo" />
</div>
</template>
<script>
import LoginFacebookService from "@/services/resources/LoginFacebookService";
import LoginGoogleService from "@/services/resources/LoginGoogleService";
import LoginTwitterService from "@/services/resources/LoginTwitterService";
import ThemeService from "@/services/resources/ThemeService";

const serviceTheme = ThemeService.build();
const serviceGoogle = LoginGoogleService.build();
const serviceFacebook = LoginFacebookService.build();
const serviceTwitter = LoginTwitterService.build();

export default {
    data() {
        return {
            email: null,
            password: null,
            sentence: '',
            component_color: null,
            background_color: '#fff',
            active_register: null,
            desktop_background: null,
            mobile_background: null,
            logo: null,
            error: false,
            company_id: null,
            forget_password: window.location.pathname === '/sindico' ? 'LiquidatorRecovery' : 'ClientRecovery'
        };
    },
    methods:{
        required(image){
            if(!image || image.length == 0){
                return null;
            }
            if(image.includes('https')){
                return image;
            }

            return require(`@/assets/img/${image}`); 
        },
        login(){
            this.$validator.validateAll().then((result) => {
                if (result) {
                let url = window.location.pathname;
                let role_id = 2;//DEFAULT CLIENT

                if(url === '/sindico') {
                    role_id = 3;//LIQUIDATOR
                }

               let data = {
                    username: this.email,
                    password: this.password,
                    role_id
                };

                this.$store
                .dispatch("loginRequest", data)
                .then(() => {
                })
                .catch((err) => {
                    this.error = true;
                });
                }
            });
        },
        loginFacebook(){

            let data = {
                company_id: this.company_id,
                type: 'Client'
            };

            serviceFacebook
            .search(data)
            .then(resp => {
                window.open(resp.url);
            })
            .catch(err => {
                console.log(err);
            });

        },
        loginGoogle(){

            let data = {
                company_id: this.company_id,
                type: 'Client'
            };
            serviceGoogle
            .search(data)
            .then(resp => {
                window.open(resp.url);
            })
            .catch(err => {
                console.log(err);
            });

        },
        loginTwitter(){

            let data = {
                company_id: this.company_id,
                type: 'Client'
            };
            serviceTwitter
            .search(data)
            .then(resp => {
                window.open(resp.url);
            })
            .catch(err => {
                console.log(err);
            });
        },
        fetchTheme(){
            let data = {
                domain: window.location.hostname
            };

            serviceTheme
            .search(data)
            .then(resp => {
                resp.forEach(element => {
                    this.company_id = element.company_id;
                    switch(element.key){
                        case 'PORTAL_CLIENT_SENTENCE':
                            this.sentence = element.value;
                            break;
                        case 'PORTAL_CLIENT_COMPONENT_COLOR':
                            this.component_color = element.value;
                            break;
                        case 'PORTAL_CLIENT_BACKGROUND_COLOR':
                            this.background_color = element.value;
                            break;
                        case 'PORTAL_CLIENT_ACTIVE_REGISTER':
                            this.active_register = element.value;
                            break;
                        case 'PORTAL_CLIENT_BACKGROUND_DESKTOP':
                            this.desktop_background = element.value;
                            break;
                        case 'PORTAL_CLIENT_BACKGROUND_MOBILE':
                            this.mobile_background = element.value;
                            break;
                        case 'PORTAL_CLIENT_ACTIVE_REGISTER':
                            this.active_register = element.value;
                            break;
                        case 'SETTINGS_LOGO':
                            this.logo = element.value;
                            break;
                    }
                });
            });
        }
    },
    mounted(){
        (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:4932490,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        this.fetchTheme()
    }
}
</script>